.ContentBlock {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 60px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1700px;
}

.ProgramIndex {
    line-height: 3rem;
}