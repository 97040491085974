.PostContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 120px);
    padding: 20px;
    margin-top: 10px;
    margin-left: 1vw;
    margin-right: 1vw;
    border-radius: 5px;
    user-select: text;
    overflow-y: auto;
}