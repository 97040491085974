.RegisterAndReset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    width: 300px;
    height: calc(100vh - 60px);
}

.VerificationSendBlock {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.VerificationSendBlock button {
    width: 250px;
}