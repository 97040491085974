@import url('@radix-ui/colors/tomato-dark.css');
@import url('@radix-ui/colors/mauve-dark.css');
@import url('@radix-ui/colors/cyan-dark.css');
@import url('@radix-ui/colors/grass-dark.css');
@import url('@radix-ui/colors/amber-dark.css');
@import url('@radix-ui/colors/red-dark.css');


.dark-editor {
    --accentBase: var(--tomato-1);
    --accentBgSubtle: var(--tomato-2);
    --accentBg: var(--tomato-3);
    --accentBgHover: var(--tomato-4);
    --accentBgActive: var(--tomato-5);
    --accentLine: var(--tomato-6);
    --accentBorder: var(--tomato-7);
    --accentBorderHover: var(--tomato-8);
    --accentSolid: var(--tomato-9);
    --accentSolidHover: var(--tomato-10);
    --accentText: var(--tomato-11);
    --accentTextContrast: var(--tomato-12);

    --baseBase: var(--mauve-1);
    --baseBgSubtle: var(--mauve-2);
    --baseBg: var(--mauve-3);
    --baseBgHover: var(--mauve-4);
    --baseBgActive: var(--mauve-5);
    --baseLine: var(--mauve-6);
    --baseBorder: var(--mauve-7);
    --baseBorderHover: var(--mauve-8);
    --baseSolid: var(--mauve-9);
    --baseSolidHover: var(--mauve-10);
    --baseText: var(--mauve-11);
    --baseTextContrast: var(--mauve-12);

    --admonitionTipBg: var(--cyan-4);
    --admonitionTipBorder: var(--cyan-8);

    --admonitionInfoBg: var(--grass-4);
    --admonitionInfoBorder: var(--grass-8);

    --admonitionCautionBg: var(--amber-4);
    --admonitionCautionBorder: var(--amber-8);

    --admonitionDangerBg: var(--red-4);
    --admonitionDangerBorder: var(--red-8);

    --admonitionNoteBg: var(--mauve-4);
    --admonitionNoteBorder: var(--mauve-8);

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    color: var(--baseText);
    --basePageBg: #212121;
    background-color: var(--basePageBg);
}