.HomeIndexContent-0 {
    transform: translateY(0);
    transition: transform 1s cubic-bezier(0.95, 0, 0.38, 1);
}

.HomeIndexContent-1 {
    transform: translateY(calc(-100vh + 60px));
    transition: transform 1s cubic-bezier(0.95, 0, 0.38, 1);
}

.slide-out-blurred-top:focus {
    -webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

.slide-out-blurred-top:active {
    -webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

@-webkit-keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}
@keyframes slide-out-blurred-top {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

.slide-out-blurred-bottom:focus {
    -webkit-animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

.slide-out-blurred-bottom:active {
    -webkit-animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

@-webkit-keyframes slide-out-blurred-bottom {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}
@keyframes slide-out-blurred-bottom {
    0% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}